import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import GalleryComponent from "../components/Gallery";

export const IndexPageTemplate = ({
  image,
  blogbackground,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  description2,
  intro,
}) => (
    <div>
      <div className="jumbotron-background-img">
        <BackgroundImage fluid={image.childImageSharp.fluid}>
          <div className="home-headers-wrapper">

            <h1 className="home-h1">{title}</h1>
            <h3 className="home-subheader">{subheading}</h3>
            <Link to="/contact" className="primary-btn">
              Contact Us
            </Link>
          </div>
        </BackgroundImage>

      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="flex-row wrap-reverse">
                    <div className="one-half">
                      <h2 className="title">{mainpitch.title}</h2>
                      <div>{mainpitch.description}</div>
                      <Link to="/gallery" className="secondary-btn">
                        Our Work
                    </Link>
                    </div>
                    <div className="one-half">
                      <Img fluid={mainpitch.image.childImageSharp.fluid} alt="custom handmade furnture dining table with custom furniture handmade wood benches by furniture maker Tim Cannon"/>
                    </div>
                  </div>

                  <div className="breakout home-gallery-breakout">
                    <div className="flex-row">
                      <div className="one-half">
                        <div className="word-wrapper">
                          <h2>{heading}</h2>
                          <p>{description}</p>
                          <p>{description2}</p>
                          <Link to="/contact" className="primary-btn">
                            Get Your Free Quote
                        </Link>
                        </div>
                      </div>
                      <div className="one-half image-grid">
                        <GalleryComponent />
                      </div>
                    </div>
                  </div>

                  <Features gridItems={intro.blurbs} />
                  <div className="columns">
                    <div className="column is-12">
                      <Link className="secondary-btn ghost quad-end" to="/products">
                        Products
                    </Link>
                    </div>
                  </div>
                  <BackgroundImage fluid={blogbackground.childImageSharp.fluid} className="blog breakout">
                    <div className="inner-shadow"></div>
                    <h3 className="has-text-weight-semibold is-size-2">
                      Latest stories
                    </h3>
                    <div className="blog-flex-column">
                      <BlogRoll />
                      <div className="column is-12 has-text-centered">
                        <Link className="primary-btn" to="/blog">
                          Read more
                      </Link>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  blogbackground: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  description2: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout canonical="https://www.cannoncustomwoodworking.com/">
      <IndexPageTemplate
        image={frontmatter.image}
        blogbackground={frontmatter.blogbackground}
        title={frontmatter.title}
        heading={frontmatter.heading}
        header={frontmatter.header}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        description2={frontmatter.description2}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 40) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        blogbackground {
          childImageSharp {
            fluid(maxWidth: 900, quality: 40) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        description
        description2
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            header
            text
          }
          heading
          description
        }
      }
    }
  }
`;
